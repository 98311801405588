.section-category .section-category--grid {
  .row .container .section-category__container {
    .section-category__columns .section-category__columns__item .section-category__columns--7item {
      .category-super-item .category-super-item--inline {
        height: rem(67px);
      }
    }
  }
}
  
.category-super-item--rectangle {
  height: rem(67px);
}

.repurchase-title {
  font-size: rem(20px);
  margin-bottom: rem($andes-spacing-16);
}
