@import '~@polycard/phrase/build';
@import '~@polycard/card/build';

$width-desktop-eshops: 1180px;

.home {
  padding-top: 0;
  padding-bottom: 0;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.ui-ms-profile__header {
  @include z-index(eshop-header);

  margin-top: 0;
  position: sticky;
  top: 0;
}

.empty-state {
  gap: $andes-spacing-12;
  margin: 0 auto;
  margin-top: rem(100px);
}

.home--affiliate {
  .poly-column__brand+.poly-column__title {
    color: $andes-gray-900;
  }

  .ui-ms-polycard-container .poly-price-current .andes-money-amount {
    .andes-money-amount__currency-symbol {
      margin-right: 0;
    }
  }
}

.ui-recos-carousel-wrapper__text {
  user-select: none;
}

.ui-ms-polycard-carousel,
.ui-ms-products-gallery__polycard {
  .poly-card--grid-card .poly-card__content:not(:last-child) { 
    padding: var(--poly-padding-content);
  }

  .ui-ms-products-gallery__container li {
    display: flex;
    width: 100%;
  }

  .grid-container.ui-ms-grid-container__eshops.ui-ms-grid-container__eshops--seller {
    display: flex;
  }

  .poly-card--grid-card {
    margin-bottom: rem(1px);
  }
}

.ui-ms-polycard-carousel {
  .andes-carousel-snapped__wrapper {
    height: auto;
  }

  .andes-carousel-snapped__slide {
    height: auto;
  }
}
