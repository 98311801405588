@import '../home';
@import './common';

@import '~@mshops-components-library/item/src/styles/desktop';
@import '~@mshops-components-library/common/src/Components/Section/styles/desktop';
@import '~@mshops-components-library/video-and-text/src/styles/general/desktop';
@import '~@mshops-components-library/video-and-text/src/styles/eshops/desktop';
@import '~@mshops-components-library/categories-gallery/src/styles/base-eshops/desktop';
@import '~@mshops-components-library/categories-gallery/src/CategoryLabelImage/styles/base/desktop';

// Categories Gallery
@import '~@mshops-components-library/category-super/src/styles/desktop';

@import '~@mshops-components-library/item/src/styles/eshops/desktop';
@import '../../../../components/badge-carousel/styles/desktop';
@import '../../../../components/message/styles/desktop';
@import '../../../../appearance/snappedCollection/styles/desktop';

// Video player
@import '../../../../components/VideoPlayer/styles/desktop';

// Banners Carousel
@import '@mshops-components-library/banners-carousel/src/styles/desktop';

// Relevant Item Container
@import '@mshops-components-library/relevant-item-container/styles/desktop';

//Merch Slider Component
@import '../../../../appearance/merchSlider/styles/desktop';

//Repurchase
@import 'eshops-components-library/src/components/Repurchase/lib/desktop';
@import '../../../../appearance/repurchase/styles/desktop.scss';

// Splitner
@import '~@mshops-components-library/splinter-grid/src/styles/eshops/desktop';

// Adn Banner
@import '../../../../components/AdnBanner/styles/desktop';

@import '../../../../components/dynamicLayout/styles/desktop';

.carousel__container-3
  .carousel__container-body
  .andes-carousel-snapped__container--full
  .andes-carousel-snapped__control--previous {
  @include z-index(below-eshop-header);
}

@import 'eshops-components-library/src/components/ProfileHeader/lib/desktop';

@import 'eshops-components-library/src/components/EmptyState/lib/desktop';

@import 'eshops-components-library/src/components/AffiliateFeeInfo/lib/desktop';

@import '@mshops-components-library/eshop-item/styles/desktop';

@import '@mshops-components-library/value-prop/styles/index';

@import '@mshops-components-library/free-shipping-bar/styles/desktop';

//Recommendation Carousel Component
@import '@mshops-components-library/recommendations-carousel/styles/desktop';

.ui-ms-polycard-container {
  .poly-card--list .poly-component__title, .poly-card--list-card .poly-component__title {
    color: $andes-gray-900;
  }

  .poly-component__action-links .poly-action-links__action--button .andes-button {
      font-size: rem($font-size-14);
      height: rem(32px);
      width: rem(263px);
  }

  .poly-column__title:first-child {
    color: $andes-gray-900;
  }

  .poly-card--list .poly-card__portada, .poly-card--list-card .poly-card__portada, .poly-column__portada--list, .poly-column__portada--list-card {
    height: rem(196px);
    width: rem(196px);
  }

  .poly-card__portada {
    border-bottom-left-radius: rem($border-radius-6);
    border-bottom-right-radius: rem($border-radius-6);
    min-width: rem(196px);
    min-height: rem(196px);
  }

  .poly-content {
    gap: rem(200px);

    .poly-content__column {
      max-width: rem(290px);
    }
  }
}

.home--supermarket .section-category {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: rem(1184px);
  padding: 0;
  width: 100%;
}

.home--supermarket {
  .section-text > .section-text__container {
    margin: 0 !important;

    .section-text__text {
      font-family: 'Proxima Nova';
      font-size: $font-size-12;
      font-style: normal;
      font-weight: $font-weight-regular;
      line-height: rem(15px);
    }
  }

  .row.container.section-category__container .section-category__title {
    margin-bottom: 0;
  }
}

.category-super-item__title {
  min-height: rem(30px);
}

.home--supermarket .section-category {
  box-sizing: border-box;
  padding: 0;
  margin: 0 auto;

  section > .container {
    max-width: 1184px;
    box-sizing: border-box;
    width: 1184px;
  }
}

.mshops-recommendations-wrapper .section-eshop__title--layout {
  padding: 0 8px;
}

.mshops-dynamic-carousel-wrapper {
  .andes-carousel-snapped__container {
    .andes-carousel-snapped__header {
      display: none;
    }
  }
}

.recommendations.ui-ms-section-eshops {
  .row.container {
    margin-top: rem(42px);
    padding-top: 0;
  }
}

.ui-eshop-item__content--column {
  min-height: 166px;
}

.affiliate-information {
  max-width: 1168px;
  bottom: 0;
}

section {
  > .container {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.ui-relevant-item-container {
  margin-top: rem($andes-spacing-24);
  padding: 0 rem($andes-spacing-12);
}
