.category-title {
  font-size: rem($andes-spacing-28);
  font-weight: $font-weight-semibold;
  max-width: rem(1184px);
  margin: rem($andes-spacing-32) auto;
  text-align: left;
}

.dynamic-carousel {
  .container {
    padding-top: 0;
    padding-bottom: rem($andes-spacing-40);
  }

  .mshops-recommendations-wrapper {
    .section-eshop__title--layout {
      padding-left: rem(2px);

      .section-header__title--base {
        font-size: rem($font-size-24);
      }
    }
  }

  .andes-carousel-snapped__header {
    margin: 0;
  }

  .andes-carousel-snapped--scroll-hidden {
    padding-top: 0;
  }

  .ui-eshop-item__content--column {
    min-height: auto;
  }

  .andes-carousel-snapped__slide {
    height: auto;
  }
}
