@import '../eshops/desktop-eshops';
// Relevant Grid Container
@import '@mshops-components-library/relevant-grid-container/styles/desktop';

.ui-ms-polycard-container-list {
  display: flex;
  flex-wrap: wrap;
  gap: 14.5px;
  justify-content: flex-start;

  .poly-card--grid, .poly-card--grid-card {
    --poly-max-width: 284px;
  }
}

.ui-ms-polycard-container {
  .poly-component__action-links .poly-action-links__action--button .andes-button {
      width: rem(200px);
  }

  .poly-content {
    gap: rem($andes-spacing-8);
  }

  .poly-card--list .poly-card__portada, .poly-card--list-card .poly-card__portada, .poly-column__portada--list, .poly-column__portada--list-card {
    height: rem(240px);
    width: rem(240px);
  }

  .poly-card__portada {
    border-bottom-left-radius: rem($border-radius-6);
    border-bottom-right-radius: rem($border-radius-6);
    min-width: rem(240px);
    min-height: rem(240px);
  }
}

@media only screen and (min-width: 768px){
  .affiliate-information {
    max-width: rem(1162px);
  }
}

.ui-relevant-item-container {
  margin-top: rem($andes-spacing-48);
}
