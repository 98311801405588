.mshops-recommendations-wrapper__collection {
  .ui-ms-section-eshops--super {
    .row.container {
      max-width: rem(1184px);

      .section-eshop__title--layout {
        padding: 0;
      }

      .andes-carousel-snapped__container {
        margin: 0 auto;
        width: rem(1184px) !important;
      }
    }

    .andes-carousel-snapped__slide {
      height: auto;
      cursor: pointer;
    }
  }
}
