@import 'merch-realestates-web/src/realestates-web/index';
@import '~@andes/card/index';
@import '../skeleton/styles/skeleton';

.ui-ms-merch-slider {
  height: 166px;
  width: rem(1184px);
  margin: rem(42px) auto;
  overflow: hidden;

  .realestates-web__layout-container {
    width: 90%;
  }

  .realestates-web__banner-single {
    max-width: rem(1184px);
    width: 111%;
  }

  .andes-card {
    height: 100%;
    max-width: rem(1184px);
  }

  .andes-carousel-snapped__control {
    margin: rem($andes-spacing-18);
    transform: rotate(0deg) translateY(-90%);

    span {
      align-items: center;
      display: flex;
    }
  }
}
