@keyframes colors {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 0%;
  }

  100% {
    background-position: 50% 100%;
  }
}

.bannerplacement-skeleton {
  animation: colors 5s ease infinite;
  background-color: transparent;
  background-image: linear-gradient(to right, #e6e6e6 42%, rgb(211 207 207));
  background-size: 200% 200%;
  height: rem(165px);
  width: 100%;

  @media (width <= 768px) {
    height: rem(127px);
    width: 98%;
  }

  @media (width <= 480px) {
    height: rem(112px);
    width: 96%;
  }
}
